import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import classNames from "classnames"
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import * as style from "./OptionsSection.module.scss"

export const fragment = graphql`
  fragment OptionsSectionFragment on WpPage_Flexlayouts_FlexibleLayouts_OptionsSection {
    sectionTitle
    options {
      logo {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 64
              height: 60
              quality: 75
              placeholder: NONE
            )
          }
        }
      }
      optionName
      active
    }
    description
  }
`

const OptionsSection = ({ sectionTitle, options, description }) => {
  return (
    <>
      <Container className={containerClass} fluid="md">
        <h2
          className="sr-only"
          dangerouslySetInnerHTML={{ __html: sectionTitle }}
        />
        <Row className={optionRowClass}>
          <ul className={style.optionList}>
          {options.map((item, i) => (
            <li className={style.optionItem} key={i} >
              <GatsbyImage
                  className={style.icon}
                  image={getImage(item.logo.localFile)}
                  alt={item.logo.altText ? item.logo.altText : "Option Icon"}
              />
              <h3
                className={
                  item.active ? optionNameClass : optionNameDisabledClass
                }
                dangerouslySetInnerHTML={{ __html: item.optionName }}
              />
            </li>
          ))}
          </ul>
        </Row>
        <p
          className={style.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Container>
    </>
  )
}

const containerClass = classNames("pt-2 pb-5 pt-md-4 pb-md-xlarge")
const optionRowClass = classNames("d-flex", "justify-content-center", "pb-2")
const optionNameClass = classNames("text-center", "text-uppercase")
const optionNameDisabledClass = classNames("text-greyed", "text-center", "text-uppercase")

export default OptionsSection

