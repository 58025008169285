// extracted by mini-css-extract-plugin
export var bgGrey100 = "OptionsSection-module--bg-grey-100--d11df";
export var bgGrey150 = "OptionsSection-module--bg-grey-150--09cda";
export var bgGrey200 = "OptionsSection-module--bg-grey-200--95ce9";
export var bgGrey300 = "OptionsSection-module--bg-grey-300--1ddbb";
export var bgGrey400 = "OptionsSection-module--bg-grey-400--c452d";
export var bgGrey500 = "OptionsSection-module--bg-grey-500--8d97a";
export var bgGrey600 = "OptionsSection-module--bg-grey-600--d9c9a";
export var bgGrey700 = "OptionsSection-module--bg-grey-700--52e4a";
export var bgGrey800 = "OptionsSection-module--bg-grey-800--39cc6";
export var bgGrey900 = "OptionsSection-module--bg-grey-900--8b24f";
export var description = "OptionsSection-module--description--dfab1";
export var icon = "OptionsSection-module--icon--f3ba0";
export var optionItem = "OptionsSection-module--optionItem--41a7f";
export var optionList = "OptionsSection-module--optionList--03b4c";
export var textGrey100 = "OptionsSection-module--text-grey-100--9b233";
export var textGrey150 = "OptionsSection-module--text-grey-150--71657";
export var textGrey200 = "OptionsSection-module--text-grey-200--f8a52";
export var textGrey300 = "OptionsSection-module--text-grey-300--bd13c";
export var textGrey400 = "OptionsSection-module--text-grey-400--fd733";
export var textGrey500 = "OptionsSection-module--text-grey-500--1f482";
export var textGrey600 = "OptionsSection-module--text-grey-600--48d56";
export var textGrey700 = "OptionsSection-module--text-grey-700--b66ab";
export var textGrey800 = "OptionsSection-module--text-grey-800--1db0b";
export var textGrey900 = "OptionsSection-module--text-grey-900--6fb36";